import React from 'react';

export const AnimatedArrow = () => {
  return (
    <svg
      width="29"
      height="118"
      viewBox="0 0 29 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3378 1C43.1185 55.1139 -7.51818 43.9205 9.59387 115"
        stroke="#D2D8DF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeDasharray="12"
        strokeDashoffset="1"
      >
        <animate
          attributeName="stroke-dashoffset"
          values="120;0"
          dur="5s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M0.788128 110.183L9.66096 115.403L14.8809 106.53"
        stroke="#D2D8DF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
