import React from 'react';
import styled from 'styled-components';
import { CourseRoadMapItem } from './CourseRoadMapItem';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  ${toScreen(desktopFirstV3.md)} {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;

    & > *:last-child:nth-child(odd) {
      grid-column: 1 / -1;
      max-width: 340px;
    }
  }

  ${toScreen(desktopFirstV3.sm)} {
    grid-template-columns: 1fr;

    & > *:last-child:nth-child(odd) {
      grid-column: auto;
      max-width: 100%;
    }
  }
`;

export const CourseRoadMap = ({ courses, levels }) => {
  const totalCourses = courses.length;
  return (
    <Wrapper>
      {courses.map((course, index) => (
        <CourseRoadMapItem
          key={index}
          course={course}
          levels={levels}
          hasNextArrow={index !== totalCourses - 1}
        />
      ))}
    </Wrapper>
  );
};
