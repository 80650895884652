import { AnimatedNumber } from 'components/Animations';
import { Typography } from 'components/DesignSystemV2/Typography';
import {
  CommentSparkleIcon,
  RocketIcon,
  StarsIcon
} from 'components/Icon/SVGIcons';
import React from 'react';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { toScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  display: flex;
  gap: 16px;

  ${toScreen(desktopFirstV3.sm)} {
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;

  ${toScreen(desktopFirstV3.sm)} {
    flex: unset;
    flex-basis: calc(100% / 2 - 32px / 2);
  }
`;

const ItemValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    opacity: 0.6;
  }
`;

const IconRenderer = ({ iconKey }) => {
  switch (iconKey) {
    case 'sparkle':
      return <StarsIcon size={24} />;
    case 'rocket':
      return <RocketIcon size={24} />;
    case 'commentSparkles':
      return <CommentSparkleIcon size={24} />;
    default:
      return null;
  }
};

const ItemContainer = ({ icon, value, unit, description }) => {
  return (
    <Item>
      <ItemValue>
        <IconRenderer iconKey={icon} />
        <Typography variant="bold/24-32" color={colorsV2.primary100}>
          <AnimatedNumber from={unit ? 1 : 0} to={value} suffix={unit} />
        </Typography>
      </ItemValue>

      <Typography variant="regular/14-20" color={colorsV2.neutral140}>
        {description}
      </Typography>
    </Item>
  );
};

export const CourseLandingHeaderHighlights = ({ highlights }) => {
  return (
    <Container>
      {highlights.map((item, index) => (
        <ItemContainer key={index} {...item} />
      ))}
    </Container>
  );
};
