import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { SectionV3 } from 'components/SectionV3';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import styled from 'styled-components';
import { CourseRoadMap } from 'shared/CourseLandingV3/CourseRoadMap';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

export const ToeicAndPronunciationLearningPath = ({ data, levels }) => {
  const { title, description, courses } = data;
  return (
    <SectionV3 id="road-map">
      <ResponsiveContainer>
        <Wrapper>
          <HeaderWrapper>
            <Typography variant="medium/16-24" color={colorsV2.neutral120}>
              {description}
            </Typography>
            <Typography
              color={colorsV2.black100}
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              as="h2"
              v3
            >
              {title}
            </Typography>
          </HeaderWrapper>
          <CourseRoadMap courses={courses} levels={levels} />
        </Wrapper>
      </ResponsiveContainer>
    </SectionV3>
  );
};
