import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen, toScreen } from 'utils/media-query/responsive.util';
import { CourseLandingHeaderHighlights } from './CourseLandingHeaderHighlights';
import { AnimatedText } from 'components/Animations';
import { ContactDolButton } from 'components/ContactDolButton';
import OutlinedButton from 'components/OutlinedButton';
import { desktopFirstV3, mobileFirstV3 } from 'style/breakpointsv3';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;

  .link-messenger {
    width: fit-content;
  }
  .tag-header {
    width: fit-content;
    border-radius: 38px;
  }
  .header-des {
    width: 100%;
  }
  .fb-mess {
    margin-right: 6px;
  }
  .btn-action {
    border-radius: 12px;
  }

  ${fromScreen(mobileFirstV3.md)} {
    align-items: flex-start;
    text-align: left;
  }

  ${fromScreen(776)} {
    .header-des {
      width: 470px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.neutral20};
  margin: 8px 0;
`;

const CtasWrapper = styled.div`
  display: flex;
  gap: 8px;

  ${toScreen(desktopFirstV3.xs)} {
    flex-direction: column;
    align-items: center;
  }
`;

const ViewCoursesButton = styled(OutlinedButton)`
  height: 44px;
  border-radius: 12px;
  border: 1px solid ${colorsV2.paper60};
  color: ${colorsV2.paper120};
  background-color: transparent;
`;

export const CourseLandingHeaderContent = ({ data }) => {
  const { title, description, highlights } = data || {};

  const viewCoursesClick = () => {
    const roadmapSection = document.getElementById('road-map');
    if (roadmapSection) {
      roadmapSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Main>
      <Typography
        variant="bold/32-40"
        tabletVariant="bold/40-48"
        desktopVariant="bold/48-56"
        color={colorsV2.neutral200}
        as="h1"
        v3
      >
        <AnimatedText text={title} color={colorsV2.neutral200} />
      </Typography>
      <Typography
        className="header-des"
        variant="regular/16-24"
        color={colorsV2.neutral120}
      >
        {description}
      </Typography>
      <CtasWrapper>
        <ContactDolButton>Liên hệ DOL ngay</ContactDolButton>
        <ViewCoursesButton onClick={viewCoursesClick}>
          Xem khoá học
        </ViewCoursesButton>
      </CtasWrapper>
      <Divider />
      <CourseLandingHeaderHighlights highlights={highlights} />
    </Main>
  );
};
