import * as React from 'react';
import styled from 'styled-components/macro';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: end;
  grid-template-columns: 1fr;
  gap: 64px;

  &.ielts-online {
    position: relative;
    z-index: 1;
    justify-content: unset;
    grid-template-rows: max-content 500px;
  }

  &.ielts {
    align-items: center;
  }
  ${fromScreen(1144)} {
    grid-template-columns: minmax(0px, 576px) minmax(0px, 424px);
    gap: 0;
    &.ielts-online {
      grid-template-rows: unset;
    }
  }
`;

export const CourseHeaderLayout = props => {
  const { mainContent, videoContent, className } = props;
  return (
    <Container className={className}>
      {mainContent}
      {videoContent}
    </Container>
  );
};
