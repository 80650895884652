import React from 'react';

export const NextArrow = ({ color = '#D2D8DF' }) => {
  return (
    <svg
      width="200"
      height="41"
      viewBox="0 0 200 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="next-arrow"
    >
      <path
        d="M0.949219 8.74152C94.79 -24.1038 75.379 59.9771 198.64 31.5629"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="12"
        strokeDashoffset="1"
      >
        <animate
          attributeName="stroke-dashoffset"
          values="120;0"
          dur="5s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M190.5 26.5L198.5 31.5L194 39.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
