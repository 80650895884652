import { Typography } from 'components/DesignSystemV2/Typography';
import { CrossIcon, CheckIcon } from 'components/Icon/SVGIcons';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 20px;
  gap: 20px;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentItem = ({ content, emotion }) => {
  const { title, description } = content;
  return (
    <Wrapper>
      <IconWrapper>
        {emotion === 'negative' ? <CrossIcon /> : <CheckIcon />}
      </IconWrapper>
      <Content>
        <Typography
          variant="semi-bold/16-24"
          desktopVariant="semi-bold/20-28"
          v3
          color={colorsV2.neutral180}
        >
          {title}
        </Typography>
        <Typography variant="regular/16-24" color={colorsV2.neutral140}>
          {description}
        </Typography>
      </Content>
    </Wrapper>
  );
};
