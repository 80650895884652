import Button from 'components/Button';
import { Typography } from 'components/DesignSystemV2/Typography';
import {
  BulbIcon,
  EyeIcon,
  RocketIcon,
  StarsIcon
} from 'components/Icon/SVGIcons';
import { RoadMapCard } from 'components/RoadMapCard';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { NextArrow } from './NextArrow';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  .next-arrow {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(25%, -75%);

    ${toScreen(desktopFirstV3.lg)} {
      transform: translate(18%, -60%);
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CoursesWapper = styled.div`
  display: flex;
  gap: 8px;

  ${toScreen(desktopFirstV3.sm)} {
    flex-direction: column;
  }
`;

const ButtonContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 14px 20px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;

  ${toScreen(desktopFirstV3.sm)} {
    padding: 12px 16px;
  }

  &.hover {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const CourseButton = styled(Button)`
  flex: 1;
  width: 100%;
  height: auto;
  line-height: auto;
  padding: 14px 20px;
  border-radius: 16px;
  height: 68px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  overflow: hidden;

  ${toScreen(desktopFirstV3.sm)} {
    height: 44px;
    padding: 12px 16px;
  }

  &:hover {
    ${ButtonContent} {
      transform: translateY(-100%);
      opacity: 0;
    }

    ${ButtonContent}.hover {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const StyledLink = styled.a`
  width: 100%;
  display: block;
`;

const IconRenderer = ({ iconName }) => {
  const props = { size: 24 };
  switch (iconName) {
    case 'bulb':
      return <BulbIcon {...props} />;
    case 'rocket':
      return <RocketIcon {...props} />;
    case 'sparkle':
      return <StarsIcon {...props} />;
    default:
      return null;
  }
};

const ViewDetailsButton = ({ course, level }) => {
  return (
    <StyledLink href={level.slug} target="_blank" rel="noopener noreferrer">
      <CourseButton>
        <ButtonContent>
          <Typography variant="regular/16-20" color={colorsV2.white100}>
            {course.display}
          </Typography>
        </ButtonContent>
        <ButtonContent className="hover">
          Chi tiết <EyeIcon />
        </ButtonContent>
      </CourseButton>
    </StyledLink>
  );
};

const CourseFooter = ({ coursesInclude, levels }) => {
  const getLevel = level => {
    return levels.find(l => l.level === level);
  };

  return (
    <Footer>
      <Typography variant="semi-bold/14-20" color={colorsV2.neutral120}>
        KHOÁ HỌC
      </Typography>
      <CoursesWapper>
        {coursesInclude.map(c => (
          <ViewDetailsButton
            key={c.level}
            course={c}
            level={getLevel(c.level)}
          />
        ))}
      </CoursesWapper>
    </Footer>
  );
};

export const CourseRoadMapItem = ({
  course,
  levels = [],
  hasNextArrow = false
}) => {
  const {
    icon,
    label,
    title,
    description,
    goals,
    duration,
    target,
    outcome,
    coursesInclude
  } = course;
  const { isDesktop } = useDeviceDetect3();

  return (
    <RoadMapCard
      label={
        <Label>
          <IconRenderer iconName={icon} />
          <Typography variant="semi-bold/16-24" color={colorsV2.primary100}>
            {label}
          </Typography>
          {hasNextArrow && isDesktop && <NextArrow />}
        </Label>
      }
      title={title}
      description={description}
      duration={duration}
      goals={goals}
      target={target}
      outcome={outcome}
      footer={<CourseFooter coursesInclude={coursesInclude} levels={levels} />}
    />
  );
};
