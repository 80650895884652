import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { ToeicAndPronunciationHeader } from 'shared/ToeicAndPronunciation/ToeicAndPronunciationHeader';
import { ToeicAndPronunciationLearningPath } from 'shared/ToeicAndPronunciation/ToeicAndPronunciationLearningPath';
import { ToeicAndPronunciationStudentExperience } from 'shared/ToeicAndPronunciation/ToeicAndPronunciationStudentExperience';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';
import { StruggleSolution } from 'components/StruggleSolution/StruggleSolution';
import { graphql } from 'gatsby';
import HonorRoll from 'components/HonorRoll';

const ToeicAndPronunciation = ({ location, data }) => {
  const { gcms, levels } = data;
  const content = gcms.jsonContanier.content;
  const flattenLevels = levels.group.flatMap(group => group.nodes);

  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Khóa học TOEIC & Phát âm"
        slug={location.pathname}
        seoTitle="Khóa học TOEIC & Phát âm"
        seoDesc="Phương pháp học độc quyền cùng công nghệ cá nhân hóa, giúp bạn đạt mục tiêu nhanh chóng."
        description="Phương pháp học độc quyền cùng công nghệ cá nhân hóa, giúp bạn đạt mục tiêu nhanh chóng."
        featureImg="cm92jrbdr91to070z96dn3gwv"
      />
      <Space>
        <ToeicAndPronunciationHeader data={content.header} />
        <StruggleSolution data={content.struggleSolution} />
        <ToeicAndPronunciationLearningPath
          data={content.roadmap}
          levels={flattenLevels}
        />
        <HonorRoll {...content.honorRoll} />
        <ToeicAndPronunciationStudentExperience data={content.studentReviews} />
        <CourseFaqsByClassGroup classGroup="TOEIC" />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export const query = graphql`
  query GetLevels($in: [String] = ["PRONUN", "PRE", "AIM 550+", "AIM 750+"]) {
    levels: allGoogleSpreadsheetDolLandingPageMarLevelData(
      filter: { level: { in: $in }, classListing: { eq: "listed" } }
    ) {
      distinct(field: level)
      group(field: level, limit: 1) {
        nodes {
          level
          whatYouGet1
          whatYouGet2
          whatYouGet3
          whatYouGet4
          whatYouGet5
          whatYouGet6
          whatYouGet7
          whatYouGet8
          slug
        }
      }
    }
    gcms {
      jsonContanier(where: { name: "ToeicPronunciation_Landing" }) {
        content
      }
    }
  }
`;

export default React.memo(ToeicAndPronunciation);
