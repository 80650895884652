import { LazyImage } from 'components/LazyImage';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import { imageCoverStyle } from 'style/css-style';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';

const cardStyle = css`
  width: 100%;
  position: relative;

  .card-image img {
    ${imageCoverStyle('cover')};
    overflow: hidden;
    border: 4px solid ${colorsV2.white100};
    border-radius: 32px;
    box-shadow: 0px 24px 32px 0px #3042780f, 0px 16px 24px 0px #3042780f,
      0px 4px 8px 0px #3042780f;
  }
`;

const Card = styled(motion.div)`
  ${cardStyle};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  max-width: 296px;

  ${toScreen(desktopFirstV3.md)} {
    order: 1;
    grid-row: 1 / 3;
  }
`;

const Emoji = styled(motion.div)`
  position: absolute;
`;

const transition = {
  duration: 0.25,
  ease: 'easeOut'
};

const struggleVariants = {
  rest: {
    opacity: 0,
    y: -20,
    transition
  },
  hover: {
    opacity: 1,
    y: 0,
    transition
  }
};

function getEmojiPosition(index) {
  const positions = [
    { top: '-6%', left: '-15%' },
    { top: '-12%', right: '2%' },
    { bottom: '9%', right: '-11%' },
    { bottom: '1%', left: '-12%' }
  ];

  return positions[index];
}

function getEmojiRestPosition(index) {
  const positions = [
    { x: 20, y: 20 },
    { x: -20, y: 20 },
    { x: -20, y: -20 },
    { x: 20, y: -20 }
  ];

  return positions[index];
}

export const StruggleCard = ({ cardInfo, ...rest }) => {
  const { cardImageHandle, emojiHandles } = cardInfo;
  return (
    <Card $aspectRatio="1/1" {...rest}>
      <LazyImage className="card-image" handle={cardImageHandle} />
      {emojiHandles.map((emojiHandle, index) => (
        <Emoji
          variants={struggleVariants}
          key={index}
          style={getEmojiPosition(index)}
        >
          <LazyImage handle={emojiHandle} />
        </Emoji>
      ))}
    </Card>
  );
};

export const SolutionCard = ({ cardInfo, ...rest }) => {
  const { cardImageHandle, emojiHandles } = cardInfo;
  return (
    <Card $aspectRatio="296/391" {...rest}>
      <LazyImage className="card-image" handle={cardImageHandle} />
      {emojiHandles.map((emojiHandle, index) => (
        <Emoji
          variants={{
            rest: {
              ...getEmojiRestPosition(index),
              ...getEmojiPosition(index),
              opacity: 0,
              transition
            },
            hover: {
              x: 0,
              y: 0,
              ...getEmojiPosition(index),
              opacity: 1,
              transition
            }
          }}
          key={index}
        >
          <LazyImage handle={emojiHandle} />
        </Emoji>
      ))}
    </Card>
  );
};
