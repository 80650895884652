import * as React from 'react';
import { ReviewWithVideoSection } from 'components/ReviewWithVideoSection';

export const ToeicAndPronunciationStudentExperience = ({ data }) => {
  return (
    <ReviewWithVideoSection
      data={data}
      footerTitle="HỌC VIÊN"
      videoPosition="left"
    />
  );
};
