import React from 'react';
import styled from 'styled-components';
import { ContentItem } from './ContentItem';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  ${toScreen(desktopFirstV3.md)} {
    order: 2;
    gap: 12px;
  }

  ${toScreen(desktopFirstV3.sm)} {
    gap: 18px;
  }
`;

export const ContentColumn = ({ contents, emotion }) => {
  return (
    <Wrapper>
      {contents.map((content, index) => (
        <ContentItem key={index} content={content} emotion={emotion} />
      ))}
    </Wrapper>
  );
};
