import React from 'react';
import { StruggleSolutionLayout } from './StruggleSolutionLayout';
import styled from 'styled-components';
import { ContactDolButton } from 'components/ContactDolButton';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  section:last-of-type {
    padding-bottom: 64px;

    ${toScreen(desktopFirstV3.md)} {
      padding-top: 100px;
    }
  }
`;

const keyArray = ['struggles', 'solutions'];

export const StruggleSolution = ({ data }) => {
  return (
    <Wrapper>
      {keyArray.map((item, index) => (
        <StruggleSolutionLayout
          data={data[item]}
          key={item}
          hasArrow={index < keyArray.length - 1}
        />
      ))}
      <ContactDolButton />
    </Wrapper>
  );
};
