import React, { useEffect, useState } from 'react';
import { motion, useMotionValue, animate } from 'framer-motion';

export const AnimatedNumber = ({
  from = 0,
  to = 100,
  duration = 2,
  prefix = '',
  suffix = ''
}) => {
  const value = useMotionValue(from);
  const [displayValue, setDisplayValue] = useState(from.toString());

  useEffect(() => {
    const animation = animate(value, to, { duration, ease: 'easeOut' });
    return () => animation.stop();
  }, [value, to, duration]);

  useEffect(() => {
    value.onChange(v => setDisplayValue(Math.round(v).toString()));
  }, [value]);

  return (
    <motion.span>
      {prefix}
      {displayValue}
      {suffix}
    </motion.span>
  );
};
