import * as React from 'react';
import styled from 'styled-components';
import { CourseHeaderLayout } from './components/CourseHeaderLayout';
import cl from 'classnames';
import { VideoThumbnail } from 'components/VideoThumbnail';
import { colorsV2 } from 'style/colors-v2';
import {
  BottomRightScribble,
  ToeicIcon,
  TopLeftScribble
} from 'components/Icon/SVGIcons';
import { CourseLandingHeaderContent } from './components/CourseLandingHeaderContent';

const Main = styled.div``;

const topLeftTranslate = 'translate(-25%, -25%)';
const bottomRightTranslate = 'translate(35%, 35%)';

const Video = styled(VideoThumbnail)`
  overflow: hidden;
  border: 3px solid ${colorsV2.paper};
  box-shadow: 0px 24px 32px 0px var(--PaperAlphaPPA10),
    0px 16px 24px 0px var(--PaperAlphaPPA10),
    0px 4px 8px 0px var(--PaperAlphaPPA10);
  border-radius: 20px;
  transform: rotateZ(2.64deg);
  transition: 0.3s ease-in-out;

  video {
    background-color: ${colorsV2.paper};
  }
`;

const RightSide = styled.div`
  position: relative;
  max-width: 396px;
  width: 100%;

  .course-thumbnail {
    width: 100%;
    margin: auto;
  }

  .scribble {
    position: absolute;
    z-index: 1;
    transition: 0.3s ease-in-out;

    &--top-left {
      top: 0;
      left: 0;
      transform: ${topLeftTranslate};
    }

    &--bottom-right {
      bottom: 0;
      right: 0;
      transform: ${bottomRightTranslate};
    }
  }

  &:hover {
    .scribble--top-left {
      transform: ${topLeftTranslate} rotateZ(15deg);
    }

    .scribble--bottom-right {
      transform: ${bottomRightTranslate} rotateZ(15deg);
    }

    ${Video} {
      transform: rotateZ(0deg);
    }
  }
`;

const TagWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  border: 2px solid ${colorsV2.white100};
  border-radius: 99px;
  overflow: hidden;
  transform: translate(-50%, -50%);

  svg {
    display: block;
  }
`;

const TagRenderer = ({ tag }) => {
  switch (tag) {
    case 'toeic':
      return <ToeicIcon />;
    default:
      return null;
  }
};

export const CourseLandingVideoHeader = ({ data, isIelts }) => {
  const { title, description, videoUrl, highlights, tag } = data || {};

  return (
    <Main>
      <CourseHeaderLayout
        className={cl({ ielts: isIelts })}
        mainContent={
          <CourseLandingHeaderContent
            data={{ title, description, highlights }}
          />
        }
        videoContent={
          <RightSide className={cl({ ielts: isIelts })}>
            <TopLeftScribble className="scribble scribble--top-left" />
            <TagWrapper>
              <TagRenderer tag={tag} />
            </TagWrapper>
            <Video
              className="course-thumbnail"
              playInline
              width={396}
              aspectRatio={isIelts ? '261/460' : '1/1'}
              backgroundColor={colorsV2.paper10}
              plyrSource={{
                type: 'video',
                sources: [
                  {
                    src: videoUrl,
                    type: 'video/mp4'
                  }
                ]
              }}
              plyrOptions={{
                controls: ['play-large']
              }}
            />
            <BottomRightScribble className="scribble scribble--bottom-right" />
          </RightSide>
        }
      />
    </Main>
  );
};
