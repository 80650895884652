import Button from 'components/Button';
import { Typography } from 'components/DesignSystemV2/Typography';
import { IconsLogoFacebook2 } from 'components/Icon/SVGIcons';
import React from 'react';
import DolInfo from 'shared/DolInfo';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { UserPixelTracking } from 'utils/userPixelTracking';

const Link = styled.a`
  text-decoration: none;
`;

const RoundedButton = styled(Button)`
  border-radius: 12px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const ContactDolButton = ({
  children = 'Liên hệ DOL tư vấn ngay',
  icon = <IconsLogoFacebook2 />
}) => {
  const onClick = () => {
    UserPixelTracking.clickEvent();
  };

  return (
    <DolInfo>
      {({ dolInfo }) => (
        <Link
          target="_blank"
          href={dolInfo.messenger.href}
          rel="noreferrer noopener"
          className="link-messenger"
        >
          <RoundedButton
            size="large"
            className="btn-action"
            type="primary"
            marginBottom="0"
            onClick={onClick}
          >
            <BtnWrapper>
              <Typography
                className="fb-mess"
                variant="semi-bold/14-24"
                color={colorsV2.white100}
                as="span"
              >
                {children}
              </Typography>
              {icon}
            </BtnWrapper>
          </RoundedButton>
        </Link>
      )}
    </DolInfo>
  );
};
