import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { CourseLandingVideoHeader } from 'shared/CourseLandingV3/CourseLandingVideoHeader';

const ToeicAndPronunciationHeaderBase = ({ data }) => {
  return <CourseLandingVideoHeader data={data} isToeic={true} />;
};

export const ToeicAndPronunciationHeader = withHomePageSection(
  ToeicAndPronunciationHeaderBase,
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
