import React from 'react';
import { motion } from 'framer-motion';

const highlightVariants = {
  hidden: {
    backgroundSize: '0% 100%',
    backgroundPosition: '0% 100%',
    backgroundRepeat: 'no-repeat'
  },
  visible: {
    backgroundSize: '100% 100%',
    transition: {
      duration: 1,
      ease: 'easeOut'
    }
  }
};

export const AnimatedText = ({ text, color = '' }) => {
  return (
    <motion.span
      variants={highlightVariants}
      initial="hidden"
      animate="visible"
      style={{
        backgroundImage: `linear-gradient(120deg, ${color} 0%, ${color} 100%)`,
        backgroundSize: '0% 100%',
        backgroundPosition: '0% 100%',
        backgroundRepeat: 'no-repeat',
        color: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text'
      }}
    >
      {text}
    </motion.span>
  );
};
