import { Typography } from 'components/DesignSystemV2/Typography';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { SectionV3 } from 'components/SectionV3';
import React from 'react';
import styled from 'styled-components';
import { ContentColumn } from './ContentColumn';
import { SolutionCard, StruggleCard } from './Cards';
import { useRotationEffect } from 'components/Animations';
import { motion } from 'framer-motion';
import { AnimatedArrow } from './AnimatedArrow';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';

const Section = styled(SectionV3)`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const Title = styled(Typography)`
  text-align: center;
  margin: 0;
`;

const Grid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  gap: 32px;

  ${toScreen(desktopFirstV3.md)} {
    grid-template-columns: 260px 1fr;
    row-gap: 12px;
  }

  ${toScreen(desktopFirstV3.sm)} {
    grid-template-columns: 1fr;
    row-gap: 18px;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 75%);
`;

const transition = {
  staggerChildren: 0.075
};

const gridVariants = {
  rest: {
    transition
  },
  hover: {
    transition
  }
};

export const StruggleSolutionLayout = ({ data, hasArrow }) => {
  const { title, leftCol, rightCol, cardImageHandle, emojiHandles, emotion } =
    data;
  const { containerRef, rotationStyle } = useRotationEffect({
    transitionDuration: 300
  });
  const cardInfo = { cardImageHandle, emojiHandles };
  const cardProps = {
    cardInfo,
    style: rotationStyle
  };

  return (
    <Section>
      <ResponsiveContainer>
        <Wrapper>
          <Title variant="bold/40-48" as="h2">
            {title}
          </Title>
          <Grid
            variants={gridVariants}
            initial="rest"
            whileHover="hover"
            animate="rest"
            ref={containerRef}
          >
            <ContentColumn contents={leftCol} emotion={emotion} />
            {emotion === 'negative' ? (
              <StruggleCard {...cardProps} />
            ) : (
              <SolutionCard {...cardProps} />
            )}
            <ContentColumn contents={rightCol} emotion={emotion} />
          </Grid>
        </Wrapper>
      </ResponsiveContainer>
      {hasArrow && (
        <ArrowWrapper>
          <AnimatedArrow />
        </ArrowWrapper>
      )}
    </Section>
  );
};
