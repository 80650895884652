import { useState, useRef, useEffect } from 'react';

/**
 * Hook that adds a rotation effect to an element based on mouse position within a container
 * @param {Object} options - Configuration options
 * @param {number} options.maxRotation - Maximum rotation in degrees (default: 10)
 * @param {number} options.perspective - CSS perspective value in pixels (default: 1000)
 * @param {number} options.transitionDuration - Transition duration in ms (default: 200)
 * @param {string} options.transitionTiming - CSS transition timing function (default: 'ease-out')
 * @param {boolean} options.resetOnLeave - Whether to reset rotation when mouse leaves (default: true)
 * @returns {Object} - Ref objects and style properties
 */
export const useRotationEffect = ({
  maxRotation = 10,
  perspective = 1000,
  transitionDuration = 200,
  transitionTiming = 'ease-out',
  resetOnLeave = true
} = {}) => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const elementRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = e => {
      const rect = container.getBoundingClientRect();

      // Calculate mouse position relative to container center
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      // Calculate rotation based on mouse distance from center
      const rotateY = ((e.clientX - centerX) / (rect.width / 2)) * maxRotation;
      const rotateX = ((centerY - e.clientY) / (rect.height / 2)) * maxRotation;

      setRotation({ x: rotateX, y: rotateY });
    };

    const handleMouseLeave = () => {
      if (resetOnLeave) {
        setRotation({ x: 0, y: 0 });
      }
    };

    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [maxRotation, resetOnLeave]);

  const rotationStyle = {
    transform: `perspective(${perspective}px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
    transformStyle: 'preserve-3d',
    transition: `transform ${transitionDuration}ms ${transitionTiming}`
  };

  return {
    containerRef,
    elementRef,
    rotationStyle
  };
};
